import React from "react";
import Typist from "react-typist";

export default function ErrorSubtitle() {
  return (
    <Typist
      startDelay={500}
      avgTypingDelay={30}
      cursor={{ show: false }}
      className='flex lg:h-32'
    >
      <code className='w-full text-sm  leading-loose'>
        <div>
          <span className='text-blue-600'>const</span>{" "}
          <span className='text-orange-400'>error</span>{" "}
          <span className='text-blue-600'>=</span> {"{"}
        </div>
        <div className='ml-8 pl-8 lg:ml-0  text-left'>
          <span className='text-red-500'>404</span>, <br />
          <span className='text-red-500'>Page Not Found</span>, <br />
        </div>
        <div className='ml-8 pl-8 lg:ml-0 text-left'>
          <span className='text-red-500'>...</span>{" "}
        </div>
        <div>{"}"};</div>
      </code>
    </Typist>
  );
}
