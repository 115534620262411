import React, { useContext, useEffect } from "react";
import { Link } from "gatsby";

import styles from "./more.module.css";
import ThemeContext from "../context/ThemeContext";
import ErrorSubtitle from "../components/ErrorSubtitle";

// styles
const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
};
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
};

const paragraphStyles = {
  marginBottom: 48,
};
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
};

// markup
const NotFoundPage = () => {
  const { dark, toggleDark } = useContext(ThemeContext);

  useEffect(() => {
    if (typeof window !== `undefined`) {
      const WOW = require("wowjs");
      new WOW.WOW({ live: false, mobile: true }).init();
    }
  }, []);

  return (
    <div
      className={
        (dark ? styles.dark : styles.light) + ` flex flex-row h-screen`
      }
    >
      <div className='m-auto'>
        <ErrorSubtitle />
        <Link to='/'>
          <button
            className='px-12 py-2 mt-6 text-xl font-bold text-center text-white uppercase rounded-full cursor-pointer font-header bg-primary-500 hover:bg-primary-600 md:text-xl focus:outline-none wow fadeInLeft'
            aria-label='Send an email'
          >
            Back to home
          </button>
        </Link>
      </div>
    </div>
  );
};

export default NotFoundPage;
